<template>
  <div class="tellus">
    <section class="banner-cuentanos">
      <img src="@/assets/images/parallax-04.png" alt="cuentanos" />
    </section>

    <section class="section context-dark bg-accent section-cta">
      <div class="container container-wide">
        <div class="row row-30 align-items-center">
          <div class="col-xl-12 wow blurIn">
            <h3>
              <span class="font-weight-bold">{{ $t('lang.tellUs.section_1.subtitle') }}</span>
            </h3>
            <h5 style="margin-top: 10px">
              {{ $t('lang.tellUs.section_1.subtitle') }}
            </h5>

            <!-- <br>
            <a class="button button-gray-light-outline">GET IT NOW</a>-->
          </div>
        </div>
      </div>
    </section>

    <!-- Form -->
    <v-app>
      <v-main>
        <section id="company-form">
          <div class="page-content">
            <div class="wizard-heading">
              <h2 class="v-desktop">
                <b>{{ $t('lang.tellUs.section_1.title') }}</b>
              </h2>
              <h2 class="v-mobile">
                <b>{{ $t('lang.tellUs.section_2.title2') }}</b>
              </h2>
            </div>
            <div class="wizard-v6-content">
              <div class="wizard-form">
                <formCompany />
              </div>
            </div>
          </div>
        </section>
      </v-main>
    </v-app>

    <section class="section novi-bg novi-bg-img section-xl text-center background-section">
      <div class="container pl-4 pr-4">
        <h2>
          <b>{{ $t('lang.tellUs.section_3.title') }}</b>
        </h2>
        <div class="row no-gutters timeline-variant-1 mt-5">
          <div class="col-sm-12">
            <div class="timeline-inner-box view-animate">
              <time>{{ $t('lang.tellUs.section_3.step') }} 01</time>
              <h5>
                <a class="timeline-title" href="#">{{ $t('lang.tellUs.section_3.step_1.title') }}</a>
              </h5>
              <p>
                {{ $t('lang.tellUs.section_3.step_1.description') }}
              </p>
            </div>
          </div>
          <div class="col-sm-12">
            <div class="timeline-inner-box view-animate">
              <time>{{ $t('lang.tellUs.section_3.step') }} 02</time>
              <h5>
                <a class="timeline-title" href="#">{{ $t('lang.tellUs.section_3.step_2.title') }}</a>
              </h5>
              <p>
                {{ $t('lang.tellUs.section_3.step_2.description') }}
              </p>
            </div>
          </div>
          <div class="col-sm-12">
            <div class="timeline-inner-box view-animate">
              <time>{{ $t('lang.tellUs.section_3.step') }} 03</time>
              <h5>
                <a class="timeline-title" href="#">{{ $t('lang.tellUs.section_3.step_3.title') }}</a>
              </h5>
              <p>
                {{ $t('lang.tellUs.section_3.step_3.description') }}
              </p>
            </div>
          </div>
          <div class="col-sm-12">
            <div class="timeline-inner-box view-animate">
              <time>{{ $t('lang.tellUs.section_3.step') }} 04</time>
              <h5>
                <a class="timeline-title" href="#">{{ $t('lang.tellUs.section_3.step_4.title') }}</a>
              </h5>
              <p>
                {{ $t('lang.tellUs.section_3.step_4.description') }}
              </p>
            </div>
          </div>
          <div class="col-sm-12">
            <div class="timeline-inner-box view-animate">
              <time>{{ $t('lang.tellUs.section_3.step') }} 05</time>
              <h5>
                <a class="timeline-title" href="#">{{ $t('lang.tellUs.section_3.step_5.title') }}</a>
              </h5>
              <p>
                {{ $t('lang.tellUs.section_3.step_5.description') }}
              </p>
            </div>
          </div>
          <div class="col-sm-12">
            <div class="timeline-inner-box view-animate">
              <time>{{ $t('lang.tellUs.section_3.step') }} 06</time>
              <h5>
                <a class="timeline-title" href="#">{{ $t('lang.tellUs.section_3.step_6.title') }}</a>
              </h5>
              <p>
                {{ $t('lang.tellUs.section_3.step_6.description') }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Vue from 'vue';
import FormCompany from '../shared/components/form/form-company.vue';
export default Vue.extend({
  name: 'TellUs',
  metaInfo: {
    title: 'Buscas talentos para tu empresa | Connecting Tech People',
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content: '🔥 Consulta las mejores ofertas de trabajo para formar tu Empresa.'
      }
    ]
  },
  data: () => ({}),
  components: {
    FormCompany
  }
});
</script>
